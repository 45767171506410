import React from "react";

import work1 from "../assets/portfolio/work1.jpg";
import work2 from "../assets/portfolio/work2.jpg";
import work3 from "../assets/portfolio/work3.jpg";
import work4 from "../assets/portfolio/work4.jpg";
import work5 from "../assets/portfolio/work5.jpg";

const Experience = () => {
  const works = [
    {
      id: 1,
      src: work1,
    },
    {
      id: 2,
      src: work2,
    },
    {
      id: 3,
      src: work3,
    },
    {
      id: 4,
      src: work4,
    },
    {
      id: 5,
      src: work5,
    },
  ];

  return (
    <div
      id="portfolio"
      className="flex flex-col w-full md:h-screen bg-gradient-to-b from-gray-500 to-black mx-auto text-white h-max-screen top-20"
    >
      <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">
        <div className="top-100">
          <p className="text-4xl font-bold border-b-4 inline border-gray-500">
            Experience
          </p>
          <p className="py-6">Check experience below </p>
        </div>

        <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-8 px-12 sm:px-0">
          {works.map(({ id, src }) => (
            <div className="shadow-md shadow-gray-600 rounded-lg">
              <img
                key={id}
                src={src}
                className="rounded-md duration-500 hover:scale-105"
              ></img>
              <div className="flex justify-center items-center">
                <button className="w-1/2 px-2 py-3">Demo</button>
                <button className="w-1/2 px-2 py-3">Code</button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Experience;
