import React from "react";
import { FaGithub, FaFacebook, FaLinkedin } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { BsFillPersonLinesFill } from "react-icons/bs";
function SocialMedia() {
  const socials = [
    {
      id: 1,
      child: (
        <>
          LinkedIn <FaLinkedin size={30}></FaLinkedin>
        </>
      ),
      href: "https:linkedin.com",
      style: "rounded-tr-md",
      download: false,
    },
    {
      id: 2,
      child: (
        <>
          Email <HiOutlineMail size={30}></HiOutlineMail>
        </>
      ),
      href: "mailto:code.techysm@gmail.com",
      download: false,
      style: "rounded-tr-md",
    },
    {
      id: 3,
      child: (
        <>
          Github <FaGithub size={30}></FaGithub>
        </>
      ),
      download: false,
      href: "https:github.com",
      style: "rounded-tr-md",
    },
    {
      id: 4,
      child: (
        <>
          Resume <BsFillPersonLinesFill size={30}></BsFillPersonLinesFill>
        </>
      ),
      href: "",
      download: true,
      style: "rounded-tr-md",
    },
  ];

  return (
    <div className="flex flex-col top-[35%] left-0 fixed">
      <ul>
        {socials.map((item) => (
          <li className="flex flex-row justify-between items-center w-40 px-4 h-14 bg-gray-50 ml-[-100px] hover:ml-[-10px] hover:rounded-md duration-300">
            <a
              href={item.href}
              className="flex justify-between items-center w-full"
              target="_blank"
              rel="noreferrer"
              download={item.download}
            >
              {item.child}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default SocialMedia;
