import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";

const Header: React.FC = () => {
  const menus = [
    { id: 1, name: "home" },
    { id: 2, name: "portfolio" },
    { id: 3, name: "team" },
    { id: 4, name: "contact" },
  ];

  const [nav, setNav] = useState(false);

  return (
    <header className="flex justify-between items-center w-full h-20 bg-black text-white fixed px-4">
      <div className="font-signature1 text-5xl ml-4">
        <a href="#Home">Techysm</a>
      </div>

      <ul className="hidden md:flex">
        {menus.map((item) => (
          <li
            key={item.id}
            className="cursor-pointer px-4 font-medium text-gray-500 hover:scale-105  duration-500 capitalize"
          >
            {item.name}
          </li>
        ))}
      </ul>

      <div
        onClick={() => {
          setNav(!nav);
        }}
        className="cursor-pointer pr-4 text-red-500 z-10 md:hidden"
      >
        {nav ? <FaTimes size={30} /> : <FaBars size={30}></FaBars>}
      </div>

      {nav && (
        <ul className="md:hidden flex flex-col justify-center items-center absolute top-0 left-0 w-full h-screen bg-gradient-to-b from-black to-gray-800">
          {menus.map((item) => (
            <li
              key={item.id}
              className="text-2xl cursor-pointer px-4 py-3 font-medium  text-gray-500 hover:scale-105  duration-500 capitalize"
            >
              {item.name}
            </li>
          ))}
        </ul>
      )}
    </header>
  );
};

export default Header;
