import React from "react";

const About = () => {
  return (
    <div
      id="about"
      className="w-full h-screen bg-gradient-to-b from-gray-800 to-black text-white"
    >
      <div className="max-w-screen-lg mx-auto flex flex-col justify-center items-center w-full h-screen">
        <p className="text-2xl border-gray-500 pb-8">About Me</p>
        <p className="text-xl">
          Hello! I’m a seasoned Full Stack .NET Developer with over 14 years of
          experience in building innovative and efficient software solutions. My
          expertise lies in creating robust web applications and complex systems
          that not only meet client expectations but exceed them. I can help you
          achieve your goals with tailored, efficient, and scalable web
          solutions. Let’s build something great together!
        </p>
      </div>
    </div>
  );
};

export default About;
