import React from "react";

import "./App.css";
import Header from "./components/Header";
import Home from "./components/Home";
import SocialMedia from "./components/SocialMedia";
import About from "./components/About";
import Portfolio from "./components/Portfolio";
import Experience from "./components/Experience";

function App() {
  return (
    <div>
      <Header></Header>
      <Home></Home>
      <SocialMedia></SocialMedia>
      <Portfolio></Portfolio>
      <Experience></Experience>
      <About></About>
    </div>
  );
}

export default App;
