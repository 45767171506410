import React from "react";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import profile from "../assets/profile.jpg";
const Home = () => {
  return (
    <div className="h-screen w-full bg-gradient-to-b from-black to-gray-600">
      <div className="mx-auto flex flex-col items-center justify-center md:flex-row sm:flex-col h-full">
        {/* Text Section */}
        <div className="flex flex-col items-center justify-center text-center py-6 sm:py-5">
          <h2 className="text-4xl md:text-4xl font-bold text-red-400">
            I'm a Fullstack Developer
          </h2>
          <p className="text-gray-500 py-4 max-w-md">
            I have around 14 years of development experience! I can help you
            build your website and handle complex application designs.
          </p>

          <button className="group flex items-center text-white w-fit px-6 py-2 rounded-md bg-gradient-to-r from-cyan-500 to-blue-600 cursor-pointer">
            Portfolio
            <span className="group-hover:rotate-90 duration-300 ml-2">
              <MdOutlineKeyboardArrowRight size={30} />
            </span>
          </button>
        </div>

        {/* Image Section */}
        <div className="max-w-md">
          <img
            src={profile}
            className="mx-auto w-2/3 md:w-full"
            alt="profile"
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
